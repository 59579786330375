// import { ChakraProvider } from '@chakra-ui/react';
import { ChakraProvider } from "@chakra-ui/react";
import { Header } from 'components/header/Header';
import { getStrapiMedia } from "lib/media";
import theme from 'lib/theme';
import Head from "next/head";
import Script from 'next/script';
import { Footer } from '../../components/footer/Footer';

const MyApp = ( { Component, pageProps } ) => {
	const { global } = pageProps;

	return (
		<>
			<Head>
				{ global && global.attributes.siteName && (
					<title>{ global.attributes.siteName }</title>
				) }
				{ global && global.attributes.favicon && (
					<link
						rel="shortcut icon"
						href={ getStrapiMedia( global.attributes.favicon ) }
					/>
				) }
			</Head>
			<Script id="google-tag-manager" strategy="afterInteractive">
				{ `
						(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer','GTM-TMJTJCZ');
					`}
			</Script>
			<ChakraProvider theme={ theme }>
				<Header />
				<Component { ...pageProps } />
				<Footer />
			</ChakraProvider>
		</>
	);
};

export default MyApp;
