import { extendTheme } from '@chakra-ui/react';
import { withProse } from '@nikolovlazar/chakra-ui-prose';
import { Link, Text, cardTheme, headingTheme } from './variant';

const config = {
	initialColorMode: 'system',
	useSystemColorMode: true,
};

const colors = {
	brand1: {
		50: '#ECF9F5',
		100: '#C9EDE4',
		200: '#A6E2D3',
		300: '#84D7C1',
		400: '#61CCB0',
		500: "#3FC09F",
		600: '#329A7F',
		700: '#26735F',
		800: '#194D40',
		900: '#0D2620',
	},
	brand2: {
		50: '#FAF5EB',
		100: '#F1E2C6',
		200: '#E8D0A1',
		300: '#DEBE7C',
		400: '#D5AB58',
		500: "#CC9933",
		600: '#A37A29',
		700: '#7A5C1F',
		800: '#523D14',
		900: '#291F0A',
	}
};

const components = {
	Heading: headingTheme,
	Box: cardTheme,
	Link: Link,
	Text: Text
};

const styles = {
	global: ( props ) => ( {
		'iframe': {
			aspectRatio: '16 / 9',
			height: '100%',
			width: '100%'
		},
		'html': {
			scrollBehavior: 'smooth'
		},
	} )
};

const theme = extendTheme( { config, colors, components, styles }, withProse() );

export default theme;
