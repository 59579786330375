import {
	Box,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	useColorMode,
	useDisclosure
} from '@chakra-ui/react';
import { data } from 'data/menu';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { NavAccordion } from './NavAccordion';
import { NavLayout } from './NavLayout';

export const Header = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { toggleColorMode } = useColorMode();
	const menuButtonRef = useRef( null );
	const router = useRouter();

	// Close menu programmatically when the route changes
	useEffect( () => {
		onClose();
	}, [router.asPath, onClose] );

	return (
		<Box as="header" bg="bg-surface" boxShadow="md">
			<Box as="nav" maxW={ { base: 'xl', md: '7xl' } } mx="auto">
				<NavLayout onClickMenu={ onOpen } isMenuOpen={ isOpen } onToggleMode={ toggleColorMode } />
				<Drawer
					placement="left"
					initialFocusRef={ menuButtonRef }
					isOpen={ isOpen }
					onClose={ onClose }
					size="full"
				>
					<DrawerOverlay />
					<DrawerContent>
						<DrawerHeader padding="0">
							<NavLayout onClickMenu={ onClose } isMenuOpen={ isOpen } menuButtonRef={ menuButtonRef } onToggleMode={ toggleColorMode } />
						</DrawerHeader>
						<DrawerBody>
							<NavAccordion data={ data } />
						</DrawerBody>
					</DrawerContent>
				</Drawer>
			</Box>
		</Box>
	);
};
