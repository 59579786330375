import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const brand1Primary = defineStyle( {
	color: "brand1.700",
	_dark: {
		color: "brand1.400"
	}
} );

const searchItemLinkBrand1 = defineStyle( {
	color: "gray.700",
	_hover: {
		color: "brand1.700"
	},
	_dark: {
		color: "white",
		_hover: {
			color: "white"
		}
	}
} );

const cardGray = defineStyle( {
	bg: "gray.200",
	_dark: {
		bg: "gray.700"
	}
} );

export const headingTheme = defineStyleConfig( {
	variants: {
		brand1: brand1Primary,
	}
} );

export const cardTheme = defineStyleConfig( {
	variants: {
		card: cardGray,
	}
} );

export const Link = defineStyleConfig( {
	variants: {
		brand1: searchItemLinkBrand1,
	},
	baseStyle: {
		color: "brand2.500",
		_hover: {
			color: "brand2.700"
		},
		_dark: {
			color: "brand2.300",
			_hover: {
				color: "brand2.100"
			}
		}
	}
} );

export const Text = defineStyleConfig( {
	variants: {
		white: {
			color: "gray.900",
			_dark: {
				color: "gray.100"
			}
		}
	}
} );
