import { Link } from '@chakra-ui/next-js';
import { Box, Button, HStack, Highlight, Icon, IconButton, Input, InputGroup, InputLeftElement, Kbd, List, ListItem, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Stack, Text, useColorModeValue, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import algoliasearch from 'algoliasearch';
import { useEffect } from 'react';
import { FiCornerDownLeft, FiSearch } from 'react-icons/fi';
import { Configure, InstantSearch, connectHighlight, connectHits, connectSearchBox } from 'react-instantsearch-dom';

const ALGOLIA_APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID ?? '';
const ALGOLIA_SEARCH_API_KEY = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY ?? '';
const ALGOLIA_INDEX_NAME = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME ?? '';

const algoliaClient = algoliasearch( ALGOLIA_APP_ID, ALGOLIA_SEARCH_API_KEY );

function Search() {

	const { isOpen, onOpen, onClose } = useDisclosure();

	const listItemBg = useColorModeValue( 'gray.100', 'gray.600' );
	const listItemHoverBg = useColorModeValue( 'brand1.200', 'brand1.700' );

	// Listen for ctrl + k to open the search modal
	useEffect( () => {
		const handleKeyDown = ( event ) => {
			if ( event.ctrlKey && event.key === 'k' ) {
				event.preventDefault();
				onOpen();
			}
		};
		window.addEventListener( 'keydown', handleKeyDown );
		return () => {
			window.removeEventListener( 'keydown', handleKeyDown );
		};
	}, [onOpen] );

	const CustomSearchBox = connectSearchBox( ( { currentRefinement, refine } ) => (
		<InputGroup size={ 'lg' }>
			<InputLeftElement pointerEvents="none">
				<Icon as={ FiSearch } />
			</InputLeftElement>
			<Input
				type="search"
				placeholder='Cerca nel sito'
				value={ currentRefinement }
				onChange={ event => {
					refine( event.currentTarget.value );
				} } />
		</InputGroup>
	) );

	const CustomHits = connectHits( ( { hits } ) => {
		return (
			<List spacing={ 3 }>
				{ hits.length === 0 && <ListItem><Text>😞 Spiacente! La ricerca non ha prodotto risultati.</Text></ListItem>}
				{ hits.map( hit => {
					const slug = hit.courseCategory ? `/corsi-gratuiti-programmazione/${hit.slug}` : `/guida/${hit.slug}`;
					return (
						<ListItem key={ hit.objectID } py={ 3 } px={ 4 } bg={ listItemBg } borderRadius={ 6 } _hover={ { bg: listItemHoverBg } }>
							<Link href={ slug } _hover={ { textDecoration: 'none' } } variant={ 'brand1' } onClick={ onClose }>
								<HStack spacing={ 3 } justifyContent={ 'space-between' }>
									<Box>
										<Text fontSize={ 'sm' }>{ hit.courseCategory ? `Corso > ${hit.courseCategory.name}` : `Guida > ${hit.postCategory.name}` }</Text>
										<CustomHighlight attribute="title" hit={ hit } />
									</Box>
									<Icon as={ FiCornerDownLeft } />
								</HStack>
							</Link>
						</ListItem>
					);
				} ) }
			</List>
		);
	} );

	const CustomHighlight = connectHighlight( ( { highlight, attribute, hit } ) => {
		const parsedHit = highlight( {
			highlightProperty: '_highlightResult',
			attribute,
			hit,
		} );

		return (
			<span>
				{ parsedHit.map(
					( part, index ) =>
						part.isHighlighted ? (
							<Highlight styles={ { px: '0.5', py: '0.5', bg: 'brand2.100', borderRadius: 4 } } key={ index } query={ part.value }>{ part.value }</Highlight>
						) : (
							<span key={ index }>{ part.value }</span>
						)
				) }
			</span>
		);
	} );

	const [isLargerThan1024] = useMediaQuery( '(min-width: 1024px)' );

	return (
		<>
			{ isLargerThan1024 ? <Button w={ 'sm' } leftIcon={ <FiSearch /> } justifyContent={ 'space-between' } onClick={ onOpen }>
				<Stack direction="row" spacing={ 1 } flex={ 1 } justifyContent={ 'space-between' } alignItems={ 'center' }>
					<Text fontSize={ 'sm' }>Cerca</Text>
					<span style={ { lineHeight: 0 } }>
						<Kbd>ctrl</Kbd> + <Kbd>K</Kbd>
					</span>
				</Stack>
			</Button> : <IconButton variant="ghost" icon={ <Icon as={ FiSearch } fontSize="xl" /> } aria-label="Toggle color mode" onClick={ onOpen } /> }

			<Modal isOpen={ isOpen } onClose={ onClose } size={ '2xl' }>
				<ModalOverlay bg='blackAlpha.700' backdropFilter='blur(5px)' />
				<ModalContent py={ 4 }>
					<InstantSearch indexName={ ALGOLIA_INDEX_NAME } searchClient={ algoliaClient }>
						<Configure hitsPerPage={ 10 } />
						<ModalHeader>
							<CustomSearchBox />
						</ModalHeader>
						<ModalBody>
							<CustomHits />
						</ModalBody>
					</InstantSearch>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Search;
