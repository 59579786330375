import { chakra } from '@chakra-ui/react';

export const Logo = ( props ) => (
	<chakra.svg
		color="brand2.500"
		width="43"
		height="50"
		viewBox="0 0 43 50"
		{ ...props }
	>
		<path
			fill="url(#paint0_linear)"
			d="M21.42 0L0 12.5v25L21.42 50l21.16-12.352.245-.148v-25L21.42 0zm-.488 47.697L8.025 25 20.932 2.385v45.312zm.977-46.2L41.44 12.862 21.909 24.243V1.497zM7.455 24.03L1.139 12.96 20.102 1.974 7.455 24.03zM6.9 25L.961 35.395v-20.79L6.9 25zm.554.97l12.647 22.056L1.139 37.04 7.455 25.97zm14.454-.131L41.44 37.22 21.909 48.586V25.839zM22.38 25l19.532-11.365v22.73L22.381 25z"
		></path>
		<defs>
			<linearGradient
				id="paint0_linear"
				x1="44"
				y1="-1.78854"
				x2="44"
				y2="91.3879"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="currentColor" />
				<stop offset="1" stopColor="currentColor" />
			</linearGradient>
			<linearGradient
				id="paint1_linear"
				x1="417.5"
				y1="13.282"
				x2="417.5"
				y2="76.282"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="currentColor" />
				<stop offset="1" stopColor="currentColor" />
			</linearGradient>
		</defs>
	</chakra.svg>
);
