import { BiBookmarks, BiBot, BiCodeAlt, BiCoffee, BiCompass, BiLaptop, BiListUl, BiMapAlt, BiPlanet } from 'react-icons/bi';
import { BsDiscord } from 'react-icons/bs';

export const data = [
	{
		title: 'Impara',
		items: [
			{
				icon: BiPlanet,
				label: 'Corsi gratuiti',
				href: '/corsi-gratuiti-programmazione',
			},
			{
				icon: BiBookmarks,
				label: 'eBook',
				href: '/ebook-programmazione',
			},
			{
				icon: BiCompass,
				label: 'Guide',
				href: '/guide',
			},
			{
				icon: BsDiscord,
				label: 'Community Discord',
				href: 'https://discord.gg/BWvku3eqXg',
				external: true
			}
		],
	},
	{
		title: 'Su di me',
		items: [
			{
				icon: BiBot,
				label: 'Chi sono',
				href: '/chi-sono',
			},
			{
				icon: BiLaptop,
				label: 'Il mio setup',
				href: '/cosa-uso',
			},
			{
				icon: BiCoffee,
				label: 'Web Tea',
				external: true,
				href: 'https://webtea.it'
			}
		],
	},
	{
		title: 'Su questo sito',
		items: [
			{
				icon: BiListUl,
				label: 'Changelog',
				href: '/changelog'
			},
			{
				icon: BiCodeAlt,
				label: 'Stack',
				href: '/stack'
			},
			{
				icon: BiMapAlt,
				label: 'Roadmap',
				href: '/roadmap'
			}
		]
	}
];
