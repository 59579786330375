import { Link } from '@chakra-ui/next-js';
import {
	Box,
	Button,
	ButtonGroup,
	Container,
	Divider,
	Icon,
	IconButton,
	Stack,
	Text
} from '@chakra-ui/react';
import { Logo } from 'components/common/Logo';
import { FaCode, FaGithub, FaHeart, FaInstagram, FaLinkedinIn, FaTiktok, FaYoutube } from 'react-icons/fa';

export const Footer = () => (
	<Container as="footer" role="contentinfo" maxW={ { base: 'xl', md: '7xl' } }>
		<Stack
			spacing="8"
			direction={ { base: 'column', md: 'row' } }
			justify="space-between"
			py={ { base: '12', md: '16' } }
		>
			<Stack spacing={ { base: '6', md: '8' } } align="start">
				<Logo />
				<Text color="muted" fontSize="sm">
					<Icon as={ FaCode } verticalAlign="middle" color="green" /> with <Icon as={ FaHeart } verticalAlign="middle" color="red" /> by Ricci Manuel
				</Text>
			</Stack>
			<Stack
				direction={ { base: 'column-reverse', md: 'column', lg: 'row' } }
				spacing={ { base: '12', md: '8' } }
			>
				<Stack direction="row" spacing="8">
					<Stack spacing="4" minW="36" flex="1">
						<Text fontSize="sm" fontWeight="semibold" color="subtle">
							Link Utili
						</Text>
						<Stack spacing="3" shouldWrapChildren>
							<Button variant="link" as={ Link } href="/corsi-gratuiti-programmazione">Corsi</Button>
							<Button variant="link" as={ Link } href="/ebook-programmazione">eBook</Button>
							<Button variant="link" as={ Link } href="/guide">Approfondimenti</Button>
						</Stack>
					</Stack>
					<Stack spacing="4" minW="36" flex="1">
						<Text fontSize="sm" fontWeight="semibold" color="subtle">
							Legal
						</Text>
						<Stack spacing="3" shouldWrapChildren>
							<Button variant="link" as={ Link } href="/privacy-policy">Privacy</Button>
							<Button variant="link" as={ Link } href="/cookie-policy">Cookie</Button>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
		<Divider />
		<Stack
			pt="8"
			pb="12"
			justify="space-between"
			direction={ { base: 'column-reverse', md: 'row' } }
			align="center"
			spacing={ { base: '4', md: '8' } }
		>
			<Box textAlign={ { base: 'center', md: 'left' } }>
				<Text fontSize="xs" color="subtle"  maxW={ 'lg' } mb={4}>
					Ricci Manuel - Via Monte Sabotino 7/10 - Paderno Dugnano, 20037, Milano - IT08502830964
				</Text>
				<Text fontSize="xs" color="subtle"  maxW={ 'lg' }>
					Il materiale su questo sito non può essere riprodotto, distribuito, trasmesso, memorizzato o in alcun modo usato senza esplicito consenso di Ricci Manuel.
				</Text>
			</Box>
			<ButtonGroup variant="ghost">
				<IconButton as="a" href="https://www.youtube.com/channel/UCfDyxJKu5QmNmvjIciGAl7A" aria-label="YouTube" icon={ <FaYoutube fontSize="1.25rem" /> } />
				<IconButton as="a" href="https://www.tiktok.com/@manuelricci.wt" aria-label="TikTok" icon={ <FaTiktok fontSize="1.25rem" /> } />
				<IconButton as="a" href="https://www.instagram.com/manuelricci.wt/" aria-label="Instagram" icon={ <FaInstagram fontSize="1.25rem" /> } />
				<IconButton as="a" href="https://www.linkedin.com/in/riccimanuel/" aria-label="LinkedIn" icon={ <FaLinkedinIn fontSize="1.25rem" /> } />
				<IconButton as="a" href="https://github.com/manuelricci" aria-label="GitHub" icon={ <FaGithub fontSize="1.25rem" /> } />
			</ButtonGroup>
		</Stack>
	</Container>
);
